import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/seo"
import Container from "../components/Container"
import { PageTitle } from "../components/Titles"
import Button from "../components/Button"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404 Error" />
    <Container size={`md`} className={`py-20 text-center`} style={{minHeight: '60vh'}}>
    <PageTitle className={`font-medium mt-28`} size={`md:text-8xl`}>Whoops!</PageTitle>
    <p className={`text-2xl mt-5`}>Page not found...</p>
    <p>It looks like you might be lost.</p>
    <div className={`mt-10`}>
      <Button to={`/`}>Home</Button>
      <Button variation={`outline`} type={`external`} href={`https://shop.milowalkerjohnson.com`}>Shop</Button>
    </div>
    </Container>
    
  </Layout>
)

export default NotFoundPage
